.stryfe-header {
  padding-top: 1%;
  padding-bottom: 1%;
  padding-left: 6vh;
  background: linear-gradient(180deg, rgb(55, 71, 97) 0%, #374761 100%);
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 2;
  display: flex;
}

.stryfe-header-title {
  font-family: inherit;
  font-style: normal;
  font-weight: 550;
  font-size: 21px;
  color: #FFFFFF;
  margin-left: 2em;
}

.stryfe-image {
  float: right;
  position: relative;
  margin-right: 60px;
  margin-left: auto;
}

