.flexbox-container {
    display: flex;
    flex-direction: row;
    padding: 0.5%;
    width: 100%;
}
.box {
    margin-right: 100px;
}

.text-bottom{
    padding-bottom: 40px;
}

.margin{
    margin-right: 5%;
    width: 30%;
}
.iframeDimension{
    height: 78vh;
    width: 38vw;
    position: relative;
}
.growth {
    overflow-y: scroll;
}
.flex-container-two{
    display: flex;
    flex-direction: row;
    align-content: space-between;
}

.flex-item {
    padding: 10px;
    border: 1px solid black;
}

.mandatory-marker{
    color: #C21F39;
}

.spaceBetweenContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.cards-container {
    display: flex;
    align-items: baseline;
}