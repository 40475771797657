.cards {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.card {
  background: #fff;
  width: 32em;
  border-radius: 2.6em;
  border: 2px solid dimgray;
  overflow: hidden;
  cursor: pointer;
  transition: all ease 200ms;
  padding: 1em;
  box-sizing: content-box;
  height: 22em;
  margin: 1em 3em 1em 1em;
}

.card h5 {
  color: black;
  font-weight: 700;
  font-size: 0.7em;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.card-body {
  padding: 0.5em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -1em;
}

.red-600 {
  color: #ff4c52;
}



.header-border {
  border: 0.8px solid lightgray;
  border-radius: 2em;
  padding: 1em;
  display: flex;
  height: 30%;
}

.amazon-orange{
  color:#ff9900
}

.aws-squid-ink{
  color:#232f3e
}

.green-600{
  color:#1d8102
}

.green-600:hover{
  transform: scale(1.08);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.blue-600:hover{
  transform: scale(1.08);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.amazon-orange:hover{
  transform: scale(1.08);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.red-600:hover{
  transform: scale(1.08);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.blue-600{
  color:steelblue;
}

.sla-widget{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: -0.8em;
}

.widget-border:hover {
  transform: scale(1.03);
  box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
}

.completed-case-widget {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  margin-bottom: -1.6em;
}

.widget-border {
  display: flex;
  flex-direction: column;
  border: 0.8px solid lightgray;
  border-radius: 2em;
  justify-content: flex-start;
  height: 70%;
  padding: 1.2em;
  margin-top: 1.2em;
  width: fit-content;

}

.subtitle {
  margin-top: -0.8em;
}